import React from "react";

export default function Drawer({ children, isOpen, setIsOpen , isLeft}) {
  return (
    <main
      className={
        " fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen
          ? " transition-opacity opacity-100 duration-200 translate-x-0  "
          : " transition-all delay-500 opacity-0 " + (isLeft?"-":"") + "translate-x-full ")
      }
    >
      <section
        className={
          (isLeft ? "left-0":"right-0") +
          " w-screen max-w-lg absolute bg-gradient-to-br from-slate-950 to-transparent h-full shadow-xl delay-400 duration-200 ease-in-out transition-all transform " +
          (isOpen ? " translate-x-0 " : ((isLeft?"-":"") + "translate-x-full "))
        }
      >
        <article className="relative w-screen max-w-lg pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
        <button className="absolute top-6 right-4" onClick={() => setIsOpen(false)}>
            <svg className="h-6 w-6 stroke-white fill-white" viewBox="0 0 512 512">
            <polygon className="st0" points="512,52.535 459.467,0.002 256.002,203.462 52.538,0.002 0,52.535 203.47,256.005 0,459.465 
		52.533,511.998 256.002,308.527 459.467,511.998 512,459.475 308.536,256.005 	"></polygon>
            </svg>
        </button>
          <header className="p-4 font-bold text-lg">Header</header>
          {children}
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></section>
    </main>
  );
}
