import React from 'react';
import "./index.css";
import FluidSample from "./FluidSample";
import Home from "./home";
import Info from "./info";
import { BrowserRouter ,Routes, Route, Link } from 'react-router-dom';
import Drawer from './drawer';
import Header from './header';

function App() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
  <div className='overflow-clip justify-center flex flex-col min-h-screen'>
    <BrowserRouter>
    <Header isOpen={isOpen} setIsOpen={setIsOpen} titleText={"株式会社StrayCats"}/>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/Home" element={<Home/>} />
        <Route path="/Info" element={<Info/>} />
        <Route path="/FluidSample" element={<FluidSample/>} />
      </Routes>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen} isLeft={false}>
        <div className='text-white ml-8 text-4xl'>
          <Link to="/">
            <div className='flex gap-2'>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              <span className="btm-nav-label">Home</span>
            </div>
          </Link>
          <div className='h-2'></div>
          <Link to="/info">
            <div className='flex gap-2'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <div className="btm-nav-label">Info</div>
            </div>
          </Link>
          <div className='h-2'></div>
          <Link to="/FluidSample">
            <div className='flex gap-2'>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <rect x="4" y="4" width="6" height="6" rx="1" strokeWidth="3"/>
                <rect x="14" y="14" width="6" height="6" rx="1" strokeWidth="3"/>
                <rect x="4" y="14" width="6" height="6" rx="1" strokeWidth="3"/>
                <rect x="14" y="4" width="6" height="6" rx="1" strokeWidth="3"/>
              </svg>
              <span className="btm-nav-label justify-center">Fluid</span>
            </div>
          </Link>
          <div className='h-2'></div>
          <Link to="https://tarots.straycats.app">
            <div className='flex gap-2'>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <rect x="4" y="4" width="6" height="6" rx="1" strokeWidth="3"/>
                <rect x="14" y="14" width="6" height="6" rx="1" strokeWidth="3"/>
                <rect x="4" y="14" width="6" height="6" rx="1" strokeWidth="3"/>
                <rect x="14" y="4" width="6" height="6" rx="1" strokeWidth="3"/>
              </svg>
              <span className="btm-nav-label justify-center">Tarot</span>
            </div>
          </Link>
          {/*
          <div className='h-2'></div>
          <div>Menu1</div>
          <div>Menu1</div>
  <div>Menu1</div>*/
  }
        </div>
      </Drawer>
    </BrowserRouter>
  </div>
);
}

 
export default App;