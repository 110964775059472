import React, { useRef, useEffect,useState, useMemo, MouseEvent } from 'react';
import {Link } from 'react-router-dom';

function Home() {
    return(<div className="w-full my-auto text-white flex-wrap" >
        <div className='flex-wrap overflow-auto'>
            <div className="border border-gray-300 shadow rounded-md p-4 max-w-2xl w-full mx-auto">
                <div className="animate-none flex space-x-4">
                    <div className="rounded-full overflow-clip bg-gray-400 h-64 w-64">
                        <img src={"/nora.jpg"}></img>
                    </div>
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-8 bg-transparent rounded w-full text-4xl font-bold ">株式会社StrayCats</div>
                        <div className="h-12 bg-transparent rounded w-full text-2xl ">DX/System Develop/Optimize/<br></br>We Love Cat</div>
                        <div className="h-12 bg-transparent rounded text-xl">c/c++組み込み系や、Webサービス、<br/>ゲームなど様々な分野でシステム開発をサポートします。</div>
                        <div className='h-2'></div>
                        <div className="h-12 bg-transparent rounded text-xl text-right align-text-bottom">2021年10月1日設立</div>
                    </div>
                </div>
            </div>
    </div>
</div>)
}

export default Home;