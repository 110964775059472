import React from "react";

export default function Header({ isOpen, setIsOpen, titleText }) {
  return (
    <header className="flex justify-between p-4">
        <div></div>
        <h1 className="content-center text-white font-medium text-4xl">{titleText}</h1>
        <div>
            <button className="" onClick={() => setIsOpen(true)}>
                <svg className={"h-6 w-6 " + (isOpen?"stroke-transparent fill-transparent":"stroke-white fill-white")}  viewBox="0 0 24 24">
                <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
                </svg>
            </button>
        </div>
    </header>
  );
}
