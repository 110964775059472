import React, { useRef, useEffect,useState, useMemo, MouseEvent } from 'react';
import *  as THREE from 'three';
import Box2DFactory from 'box2d-wasm';
import { BloomEffect, EffectComposer, EffectPass, RenderPass } from "postprocessing";

function FluidSample() {
  const targetDivRef = useRef(null);
  const memoRender = useMemo(() => new THREE.WebGLRenderer( { antialias: true } ), [])
  // const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  
  useEffect(() => {
    if(targetDivRef==null)return;
    // init
    const camera = new THREE.PerspectiveCamera( 70, window.innerWidth / (window.innerHeight -192), 0.001, 1000 );
    camera.position.z = 1;

    const scene = new THREE.Scene();

    const texture = new THREE.TextureLoader().load('crate.gif');
    const geometry = new THREE.BoxGeometry( 0.25, 0.25, 0.125 /2);
    const material = new THREE.MeshBasicMaterial({map:texture});
    const mesh = new THREE.Mesh( geometry, material );
    scene.add( mesh );
    const mesh2 = new THREE.Mesh( geometry, material );
    scene.add( mesh2 );
    const renderer = memoRender;//new THREE.WebGLRenderer( { antialias: true } );
    renderer.cam = camera;
    renderer.setSize( window.innerWidth, window.innerHeight -192);
    renderer.setAnimationLoop( animation );
    targetDivRef.current.appendChild( renderer.domElement );
    // animation
    function animation( time ) {
      // mesh.rotation.x = time / 2000;
      // mesh.rotation.y = time / 1000;
      renderer.render( scene, camera );
      composer.render();
    }
    const object3D = new THREE.Mesh();
    var ssize = new THREE.Vector2(0,0);
    renderer.getSize(ssize);

    function onResize() {
      // サイズを取得
      const width = window.innerWidth;
      const height = window.innerHeight-192;

      // レンダラーのサイズを調整する
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);

      // カメラのアスペクト比を正す
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.getSize(ssize);
    }
    window.addEventListener('resize', onResize);
    const composer = new EffectComposer(renderer);
    composer.addPass(new RenderPass(scene, camera));
    composer.addPass(new EffectPass(camera, new BloomEffect()));


    async function initB2d(){
      var box2dinstance;
      await Box2DFactory({
       locateFile: (url, scriptDirectory) => `${scriptDirectory}${url}`
      }).then(box2D => {
        // finished downloading Box2D.wasm
        // console.log(box2D);
        box2dinstance = box2D;
      });
      const { b2BodyDef, b2_staticBody, b2_dynamicBody,
        b2_powderParticle, b2_elasticParticle, b2_biscousParticle, b2PolygonShape, b2Vec2, b2World,
        destroy,
        getPointer,
        HEAPF32,
        b2EdgeShape,
        b2ParticleGroupDef,
        b2ParticleSystemDef,
      } = box2dinstance;
      var tempPos = new THREE.Vector3();
      var mousePos = new b2Vec2(0, 0);
      var toggle = false;
      const mouseMoveListener = (event) => {
        
        // console.log(event);
        // console.log(projection);
        // console.log(ssize);
        //setMousePosition({ x: event.clientX, y: event.clientY });
        // const sx = (renderer.width / 2) * (+projection.x + 1.0);
        // const sy = (renderer.height / 2) * (-projection.y + 1.0);
        const sx = (event.clientX/ssize.width)*2 -1.0;
        const sy = -(event.clientY/ssize.height)*2 +1.0;
        tempPos.set(sx,sy,0.5);
        // console.log(tempPos);
        tempPos.unproject( camera );
        tempPos = tempPos.sub( camera.position ).normalize();
        // console.log(tempPos);
        mousePos.Set(tempPos.x,tempPos.y);
        // console.log(event);
      };
      const onMouseClick =(ev)=>{
        toggle = !toggle;
      };
      window.addEventListener("mousedown", onMouseClick);
      window.addEventListener("mousemove", mouseMoveListener);

      const gravity = new b2Vec2(0, -9.8);
      const world = new b2World(gravity);
      destroy(gravity);
      const sideLengthMetres = 1/2;
      const floorSquare = new b2PolygonShape();
      const pillarSquare = new b2PolygonShape();
      const square = new b2PolygonShape();
      floorSquare.SetAsBox(1.6,1);
      pillarSquare.SetAsBox(0.1,20);
      
      square.SetAsBox(sideLengthMetres/4, sideLengthMetres/4);

      const zero = new b2Vec2(0, 0);
      const zeroOne = new b2Vec2(0, 1);

      const bd = new b2BodyDef();
      bd.set_type(b2_staticBody);
      bd.set_position(zero);
      const bd2 = new b2BodyDef();
      bd2.set_type(b2_dynamicBody);
      bd2.set_position(zero);
      const bd3 = new b2BodyDef();
      bd3.set_type(b2_dynamicBody);
      bd3.set_position(zeroOne);

      const body0 = world.CreateBody(bd2);
      body0.CreateFixture(square, 1);
      body0.SetTransform(0,0, 0);
      body0.SetLinearVelocity(zero);
      body0.SetAwake(true);
      body0.SetEnabled(true);
      const body1 = world.CreateBody(bd3);
      body1.CreateFixture(square, 1);
      body1.SetTransform(0,0, 0);
      body1.SetLinearVelocity(zero);
      body1.SetAwake(true);
      body1.SetEnabled(true);

      const body = world.CreateBody(bd);
      body.CreateFixture(floorSquare, 1);
      body.SetTransform(new b2Vec2(0,-1.666), 0);
      body.SetLinearVelocity(zero);
      body.SetAwake(true);
      body.SetEnabled(true);
      const wall1 = world.CreateBody(bd);
      wall1.CreateFixture(pillarSquare, 1);
      wall1.SetTransform(new b2Vec2(1.0,0), 0);
      wall1.SetLinearVelocity(zero);
      wall1.SetAwake(true);
      wall1.SetEnabled(true);
      const wall2 = world.CreateBody(bd);
      wall2.CreateFixture(pillarSquare, 1);
      wall2.SetTransform(new b2Vec2(-1.0,0), 0);
      wall2.SetLinearVelocity(zero);
      wall2.SetAwake(true);
      wall2.SetEnabled(true);
      destroy(bd);
      destroy(bd2);
      destroy(bd3);
      destroy(floorSquare);
      destroy(pillarSquare);
      destroy(zero);
      destroy(zeroOne);

      // floor which boxes rest on
      // {
      //   const bd_ground = new b2BodyDef()
      //   const ground = world.CreateBody(bd_ground)
      //   const from = new b2Vec2(3, 18)
      //   const to = new b2Vec2(22, 18)
      //   const shape = new b2EdgeShape()
      //   shape.SetTwoSided(from, to)
      //   ground.CreateFixture(shape, 0)
      //   destroy(shape)

      //   destroy(bd_ground)
      //   destroy(from)
      //   destroy(to)
      // }

      const particleRadiusNominal = 0.020;
      const psd = new b2ParticleSystemDef()
      // psd.maxCount = 1
      psd.radius = particleRadiusNominal
      psd.dampingStrength = 0.2
      
      const particleSystem = world.CreateParticleSystem(psd);
      destroy(psd)
  // const temp = new b2Vec2(0, 0)
  const shape = new b2PolygonShape()
  // temp.Set(0, 1)
  // shape.SetAsBox(0.9, 0.9, temp, 0)
  // shape.position = new b2Vec2(0,1024);
  shape.SetAsBox(sideLengthMetres/16, sideLengthMetres*32);
  const particleGroupDef = new b2ParticleGroupDef()
  particleGroupDef.shape = shape
  // particleGroupDef.flag = b2_powderParticle;//b2_elasticParticle | b2_biscousParticle;
  particleGroupDef.position = new b2Vec2(0.75,16);
  particleSystem.CreateParticleGroup(particleGroupDef);
  // console.log(particleGroupDef);
  destroy(particleGroupDef)
  destroy(shape)
  var points;
  {
    var vertices=[];
    var colors=[];
const geom = new THREE.BufferGeometry();

const vertexShader = 
`attribute vec3 position;
attribute vec4 color;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
varying vec4 v_color;

void main() {
  v_color = color;

  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0 );
  gl_PointSize = 64.0;
}`;
const fragmentShader =
`precision mediump float;

varying vec4 v_color;

void main() {
  vec2 temp = gl_PointCoord - vec2(0.5);
  float f = dot(temp, temp);
  if (f > 0.25 ) {
    discard;
  } else {
    float v = (1.0-f*4.0);
    gl_FragColor = vec4(1,1,1,v)*v_color;
  }
}`;
const material = new THREE.RawShaderMaterial({
vertexShader: vertexShader,
fragmentShader: fragmentShader,
transparent: true,
blending: THREE.AdditiveBlending,
});
var psBuffer = particleSystem.GetPositionBuffer();
var velBuffer = particleSystem.GetVelocityBuffer();
var count = particleSystem.GetParticleCount();
console.log(particleSystem);
// console.log(`count:${count}`);
for (let i = 0; i < count; i++) {
  var p = getPointer(psBuffer) + i * 8;
  // console.log(`${HEAPF32[p >> 2]},${HEAPF32[p + 4 >> 2]}`);
  const x = HEAPF32[p >> 2];//1 * (Math.random() - 0.5);
const y = HEAPF32[p + 4 >> 2];//1 * (Math.random() - 0.5);
const z = 0;//10 * (Math.random() - 0.5);
// psBuffer[2*i] = x;
// psBuffer[2*i+1] = y;
vertices.push(x, y, z);
colors.push(0.3,0.7,1,1.0);
}
geom.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
geom.setAttribute('color', new THREE.Float32BufferAttribute(colors, 4));
let cloud = new THREE.Points(geom, material);
cloud.name = "particles";
scene.add(cloud);
points = cloud;
points.geometry.dynamic = true;
}
// console.log(points);
// console.log(particleSystem);

// console.log(points.geometry.attributes.position.updateRanges);
      // calculate no more than a 60th of a second during one world.Step() call
const maxTimeStepMs = 1/60*1000;
const velocityIterations = 1;
const positionIterations = 1;

const b2Force = new b2Vec2(0, 0)
/**
 * Advances the world's physics by the requested number of milliseconds
 * @param {number} deltaMs
 */
const step = (deltaMs) => {
  const clampedDeltaMs = Math.min(deltaMs, maxTimeStepMs);
  world.Step(clampedDeltaMs/1000, velocityIterations, positionIterations);
  var boxpos =body0.GetPosition();
  var boxpos2 =body1.GetPosition();
  // console.log(body0.GetAngle());
  // console.log(mesh);
  mesh.rotation.z = body0.GetAngle();
  mesh.position.x = boxpos.x;
  mesh.position.y = boxpos.y;
  mesh2.rotation.z = body1.GetAngle();
  mesh2.position.x = boxpos2.x;
  mesh2.position.y = boxpos2.y;
  // mesh.SetTransform(boxpos.x,boxpos.y,0);
  var pos = points.geometry.attributes.position.array;
  var col = points.geometry.attributes.color.array;
  for (let i = 0; i < count; i++) {
    var p = getPointer(psBuffer) + i * 8;
    var v = getPointer(velBuffer) + i * 8;
    const x = HEAPF32[p >> 2];//1 * (Math.random() - 0.5);
    const y = HEAPF32[p + 4 >> 2];//1 * (Math.random() - 0.5);
    const vx = HEAPF32[v >> 2];
    const vy = HEAPF32[v + 4 >> 2];
    var vel = Math.sqrt(vx*vx+vy*vy);
    pos[3*i] = x;
    pos[3*i+1] = y;
    pos[3*i+2] = 0;
    // col[4*i+0] = x+y;
    // col[4*i+1] = x-y;
    // col[4*i+2] = 1;
    col[4*i+3] = vel>0.3?0.3:vel*1+0.;
    if(toggle){
      var dx = mousePos.x-x;
      var dy = mousePos.y-y;
      // var pow = x*x + y*y;
      var len = Math.sqrt(dx*dx + dy*dy);
      if(len==0){len = 1}
      len = Math.max(Math.min(len, 1000),70);
      b2Force.Set(dx/len, dy/len);
      particleSystem.ParticleApplyForce(i, b2Force)
    }
  }
  points.geometry.attributes.position.needsUpdate = true;
  points.geometry.attributes.color.needsUpdate = true;
};

/**
 * Prints out the vertical position of our falling square
 * (this is easier than writing a full renderer)
 */
const whereIsOurSquare = () => {
  {
    const {x, y} = body.GetLinearVelocity();
    console.log("Square's velocity is:", x, y);
  }
  {
    const {x, y} = body.GetPosition();
    console.log("Square's position is:", x, y);
  }
};

/** @type {number} you can use this handle to cancel the callback via cancelAnimationFrame */
let handle;
(function loop(prevMs) {
  const nowMs = window.performance.now();
  handle = requestAnimationFrame(loop.bind(null, nowMs));
  const deltaMs = nowMs-prevMs;
  step(deltaMs);
  //whereIsOurSquare();
}(window.performance.now()));
    }
    initB2d();
    return ()=>{
      // destroy(world)
      // destroy(mousePos)
      // destroy(b2Force)
      // window.removeEventListener("mousedown", onMouseClick);
      // window.removeEventListener("mousemove", mouseMoveListener);
      // window.removeEventListener('resize');
    }
  }, [targetDivRef])
  
  return (<div className='m-auto' ref={targetDivRef}></div>)
}
 
export default FluidSample;